import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { jwtDecode } from "jwt-decode"; // Correct import for jwtDecode

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,
  prepareHeaders: (headers, { getState }) => {
    const state = getState();
    const token = state?.user?.token;

    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000;

        if (decodedToken.exp > currentTime) {
          headers.set("token", token);
          console.log("Token is valid and has been set in the headers.");
        } else {
          console.log("Token is expired and will not be set in the headers.");
          localStorage.removeItem("user");
          localStorage.removeItem("token");
        }
      } catch (error) {
        console.error("Failed to decode the token", error);
      }
    } else {
      console.log("No token found in state.");
    }

    return headers;
  },
});

export const projectService = createApi({
  reducerPath: "projectService",
  baseQuery,
  tagTypes: ["Project"],
  endpoints: (builder) => ({
    createProject: builder.mutation({
      query: (description) => ({
        url: "/project",
        method: "POST",
        body: { description },
      }),
      invalidatesTags: ["Project"],
    }),
    getProjectById: builder.query({
      query: ({ projectId, userId }) => {
        const params = new URLSearchParams();
        if (userId) params.append("userId", userId);
        return {
          url: `/project/${projectId}?${params.toString()}`,
          method: "GET",
        };
      },
      keepUnusedDataFor: 0, // This will prevent caching
      providesTags: (result, error, { projectId }) =>
        result ? [{ type: "Project", id: projectId }] : [],
    }),
    getAllProjects: builder.query({
      query: (userId) => {
        const params = new URLSearchParams();
        if (userId) params.append("userId", userId);
        return {
          url: `/project?${params.toString()}`,
          method: "GET",
        };
      },
      providesTags: ["Project"],
      transformResponse: (response) => response,
    }),
    getUserProjects: builder.query({
      query: (userId) => ({
        url: `/project?userId=${userId}`,
        method: "GET",
      }),
      transformResponse: (response) => response?.projects,
      providesTags: ["getUserProjects"], // Use a general "UserById" tag
      // This ensures that the query is cached as 'getUserById' without the argument
      serializeQueryArgs: () => {
        return;
      },

      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),
    updateProject: builder.mutation({
      query: ({ projectId, updatedData, userId }) => {
        const params = new URLSearchParams();
        if (userId) params.append("userId", userId);
        return {
          url: `/project/${projectId}?${params.toString()}`,
          method: "PUT",
          body: updatedData,
        };
      },
      invalidatesTags: (result, error, { projectId }) => [
        { type: "Project", id: projectId },
      ],
    }),
    updateStarredProject: builder.mutation({
      query: ({ projectId, isStarred }) => {
        return {
          url: `/project/${projectId}/update`,
          method: "POST",
          body: {
            isStarred,
          },
        };
      },
      invalidatesTags: (result, error, { projectId }) => [
        { type: "Project", id: projectId },
      ],
    }),
  }),
});

export const {
  useCreateProjectMutation,
  useGetProjectByIdQuery,
  useGetAllProjectsQuery,
  useGetUserProjectsQuery, // New hook for fetching user's projects
  useUpdateProjectMutation,
  useUpdateStarredProjectMutation,
} = projectService;
