import React, { useState, useEffect } from "react";
import {
  Table,
  Typography,
  Tooltip,
  Button,
  Modal,
  message,
  Input,
  Space,
} from "antd";
import {
  MoreOutlined,
  StarOutlined,
  StarFilled,
  SearchOutlined,
} from "@ant-design/icons";
import "tailwindcss/tailwind.css";
import { truncateText } from "../helper/utils";
import {
  useGetAllProjectsQuery,
  useUpdateStarredProjectMutation,
} from "../features/projectsSlice";
import { useSelector } from "react-redux";
import { Layout } from "antd";
import { createUseStyles } from "react-jss";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import Header from "../components/Header";
const { Title } = Typography;
const { Content } = Layout;

const ProjectsTable = () => {
  console.log("rendered table");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentDescription, setCurrentDescription] = useState("");
  const { data, error, isLoading, refetch } = useGetAllProjectsQuery();
  const [updateStarredProject] = useUpdateStarredProjectMutation();
  const navigate = useNavigate();

  const projects = useSelector(
    (state) =>
      state.projectService.queries["getAllProjects(undefined)"]?.data
        ?.projects || []
  );

  const [starredProjects, setStarredProjects] = useState([]);
  const [unstarredProjects, setUnstarredProjects] = useState([]);
  const [filteredStarredProjects, setFilteredStarredProjects] = useState([]);
  const [filteredUnstarredProjects, setFilteredUnstarredProjects] = useState(
    []
  );
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    setStarredProjects(projects.filter((p) => p.isStarred));
    setUnstarredProjects(projects.filter((p) => !p.isStarred));
    setFilteredStarredProjects(projects.filter((p) => p.isStarred));
    setFilteredUnstarredProjects(projects.filter((p) => !p.isStarred));
  }, [projects]);

  const useStyles = createUseStyles({
    mainContent: {
      padding: "0px",
    },
    searchBar: {
      marginBottom: "20px",
      display: "flex",
      justifyContent: "space-between",
    },
    table: {
      "& .ant-table-thead > tr > th": {
        backgroundColor: "#e0e0e0 !important",
      },
      "& .ant-table-tbody > tr:nth-child(even)": {
        backgroundColor: "#f0f0f0",
      },
      "& .ant-table-tbody > tr:nth-child(odd)": {
        backgroundColor: "#ffffff",
      },
      "& .ant-table-tbody > tr:hover > td": {
        backgroundColor: "#e6f7ff !important",
      },
    },
    starButton: {
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
  });
  const classes = useStyles();

  const showModal = (description) => {
    setCurrentDescription(description);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const handleStarProject = async (project) => {
    if (
      !project.isStarred &&
      starredProjects.length >= process.env.MAX_STARRED_PROJECTS
    ) {
      message.error(
        `You can't star more than ${process.env.MAX_STARRED_PROJECTS} projects.`
      );
      return;
    }

    try {
      const response = await updateStarredProject({
        projectId: project.id,
        isStarred: !project.isStarred,
      }).unwrap();
      refetch();
      if (!response.success) {
        message.error("Failed to update project star status.");
        return;
      } else {
        message.success(
          project.isStarred
            ? "Project removed from favorites."
            : "Project added to favorites."
        );
      }
    } catch (err) {
      message.error("Failed to update project star status.");
    }
  };
  const handleSearch = (value) => {
    setSearchText(value);
    const lowercasedValue = value.toLowerCase();
    const filteredStarred = starredProjects.filter(
      (project) =>
        project.title.toLowerCase().includes(lowercasedValue) ||
        project.createdAt.toLowerCase().includes(lowercasedValue) ||
        project.createdBy.toLowerCase().includes(lowercasedValue)
    );
    const filteredUnstarred = unstarredProjects.filter(
      (project) =>
        project.title.toLowerCase().includes(lowercasedValue) ||
        project.createdAt.toLowerCase().includes(lowercasedValue) ||
        project.createdBy.toLowerCase().includes(lowercasedValue)
    );
    setFilteredStarredProjects(filteredStarred);
    setFilteredUnstarredProjects(filteredUnstarred);
  };

  const handleReset = () => {
    setSearchText("");
    setFilteredStarredProjects(starredProjects);
    setFilteredUnstarredProjects(unstarredProjects);
  };

  const columns = [
    {
      title: "Star",
      key: "star",
      width: 50,
      align: "center",
      render: (_, project) => (
        <Tooltip
          title={
            project.isStarred
              ? "remove from starred projects"
              : "add to starred projects"
          }
        >
          <Button
            type="text"
            className={classes.starButton}
            icon={
              project.isStarred ? (
                <StarFilled style={{ color: "#FFD700" }} />
              ) : (
                <StarOutlined />
              )
            }
            onClick={() => handleStarProject(project)}
          />
        </Tooltip>
      ),
    },
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 80,
      align: "center",
      render: (text) => (
        <div className="whitespace-nowrap overflow-hidden text-ellipsis">
          {text}
        </div>
      ),
    },
    {
      title: "Project Title",
      dataIndex: "title",
      key: "title",
      align: "center",
      render: (text) => (
        <div className="whitespace-nowrap overflow-hidden text-ellipsis">
          {text.length > 50 ? (
            <Tooltip title={truncateText(text, 50)}>
              {truncateText(text, 10)}
              <Button
                type="link"
                icon={<MoreOutlined color="#FFD70" />}
                onClick={() => showModal(text)}
                style={{ paddingLeft: "8px" }}
              />
            </Tooltip>
          ) : (
            text
          )}
        </div>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      align: "center",
      render: (text) => (
        <div className="whitespace-nowrap overflow-hidden text-ellipsis">
          {text.length > 50 ? (
            <Tooltip title={truncateText(text, 50)}>
              {truncateText(text, 10)}
              <Button
                type="link"
                icon={<MoreOutlined color="#FFD70" />}
                onClick={() => showModal(text)}
                style={{ paddingLeft: "8px" }}
              />
            </Tooltip>
          ) : (
            text
          )}
        </div>
      ),
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center", // Center the text in this column
      render: (date) => (
        <div className="whitespace-nowrap overflow-hidden text-ellipsis">
          {date.length > 50 ? (
            <Tooltip title={date}>
              {truncateText(moment(date).format("Do MMMM YYYY"), 30)}
              <Button
                type="link"
                icon={<MoreOutlined />}
                onClick={() => showModal(date)}
                style={{ paddingLeft: "8px" }}
              />
            </Tooltip>
          ) : (
            moment(date).format("Do MMMM YYYY")
          )}
        </div>
      ),
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",
      align: "center", // Center the text in this column
      render: (text) => (
        <div className="whitespace-nowrap overflow-hidden text-ellipsis">
          {text.length > 50 ? (
            <Tooltip title={truncateText(text, 50)}>
              {truncateText(text, 50)}
              <Button
                type="link"
                icon={<MoreOutlined />}
                onClick={() => showModal(text)}
                style={{ paddingLeft: "8px" }}
              />
            </Tooltip>
          ) : (
            text
          )}
        </div>
      ),
    },
  ];
  console.log({ env: process.env.TABLE_MAX_ROWS });

  return (
    <Layout
      style={{
        minHeight: "100vh",
        position: "relative",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Header
        className="h-16 bg-gray-800 text-white p-4 flex items-center justify-between"
        style={{ height: "64px" }} // Ensure consistent height
      />
      <Content className={classes.mainContent}>
        <div
          className={`bg-white rounded-lg shadow-lg p-4 w-full  max-w-6xl mx-auto ${classes.searchBar}`}
        >
          <Space>
            <Input
              placeholder="Search projects"
              value={searchText}
              onChange={(e) => handleSearch(e.target.value)}
              style={{ width: 300 }}
              prefix={<SearchOutlined />}
            />
            <Button onClick={handleReset}>Reset</Button>
          </Space>
          <Space>
            <Button
              className="bg-blue-500 text-white"
              onClick={() => navigate("/projects")}
            >
              Add new project
            </Button>
          </Space>
        </div>
        <div className="bg-white rounded-lg shadow-lg p-4 w-full max-w-6xl mx-auto mb-4">
          <Title level={4} className="text-start mb-2">
            Starred Projects
          </Title>
          <Table
            dataSource={filteredStarredProjects}
            columns={columns}
            rowKey="id"
            pagination={false}
            loading={isLoading}
            className={`text-center ${classes.table}`}
            rowClassName={(index) =>
              index % 2 === 0 ? "table-row-light" : "table-row-dark"
            }
          />
        </div>
        <div className="bg-white rounded-lg shadow-lg p-4 w-full max-w-6xl mx-auto">
          <Title level={4} className="text-start mb-2">
            All Projects
          </Title>
          <Table
            dataSource={filteredUnstarredProjects}
            columns={columns}
            rowKey="id"
            pagination={{ pageSize: process.env.TABLE_MAX_ROWS }}
            loading={isLoading}
            className={`text-center ${classes.table}`}
            rowClassName={(index) =>
              index % 2 === 0 ? "table-row-light" : "table-row-dark"
            }
          />
        </div>
        <Modal
          title="Project Description"
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          footer={[
            <Button key="ok" type="primary" onClick={handleOk}>
              Close
            </Button>,
          ]}
        >
          <p>{currentDescription}</p>
        </Modal>
      </Content>
    </Layout>
  );
};

export default ProjectsTable;
