import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../components/Header";
import RightSidebar from "../components/RightSidebar";
import { useGetProjectByIdQuery } from "../features/projectsSlice";
import WireframeDisplay from "../components/WireframeDisplay/WireframeDisplay";

const ProjectDetails = ({
  leftCollapsed,
  rightCollapsed,
  setRightCollapsed,
  id,
  isNewUserStory,
  idFeature,
}) => {
  console.log("rendered projects detailss");
  // Fetch the project by ID using the custom hook
  const { data: project, error } = useGetProjectByIdQuery({ projectId: id });

  // Handle error state
  if (error) {
    return <p>Error fetching project details</p>;
  }

  // Handle case where project is not found
  if (!project) {
    return <p>Project not found</p>;
  }

  return (
    <div className="flex flex-col flex-1 overflow-hidden">
      <Header
        projectId={project?.id}
        className="h-16 bg-gray-800 text-white p-4 flex items-center justify-between"
        style={{ height: "64px" }} // Ensure consistent height
      />
      <div className="flex flex-1 overflow-hidden">
        <main
          className="flex-1 bg-white flex flex-col overflow-auto"
          style={{
            marginRight: rightCollapsed ? "48px" : "0px", // Adjust margin to account for sidebar width
          }}
        >
          <div className="flex flex-col flex-1">
            <WireframeDisplay
              rightCollapsed={rightCollapsed}
              leftCollapsed={leftCollapsed}
              className="flex-grow"
              isNewUserStory={isNewUserStory}
              idFeature={idFeature}
            />
          </div>
        </main>
        <RightSidebar
          isCollapsed={rightCollapsed}
          setIsCollapsed={setRightCollapsed}
        />
      </div>
    </div>
  );
};

export default ProjectDetails;
