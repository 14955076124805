import { Button, message, Spin, Tabs, Tooltip, Typography } from "antd";
import i18n from "i18next";
import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { useReactMediaRecorder } from "react-media-recorder-2";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { sendAudioForTranscription } from "../../features/audioSlice";
import { useGetAllProjectsQuery } from "../../features/projectsSlice";
import { selectWireframe } from "../../features/selectedFeatureSlice";
import { setTranscript } from "../../features/transcriptSlice";
import {
  useAddUserStoryMutation,
  useGetUserStoryByIdQuery,
  useSetVersionAsCurrentVersionMutation,
  useUpdateUserStoryVersionMutation,
} from "../../features/userStoryService";
import MessageInput from "./MessageInput";
import WireframeViewer from "./WireframeViewer";

const { Title } = Typography;
const { TabPane } = Tabs;

const useStyles = createUseStyles({
  historyButtonWrapper: (props) => ({
    display: "flex",
    bottom: "10px",
    left: props.leftCollapsed ? "80px" : "250px", // Adjust left position based on collapse state
    right: props.rightCollapsed ? "45px" : "250px", // Adjust right position based on collapse state
    transition: "left 0.3s ease, right 0.3s ease", // Smooth transition when the sidebars collapse
    zIndex: 1000,
  }),

  tabs_component: {
    "& .ant-tabs-nav .ant-tabs-nav-wrap": {
      display: "flex",
      justifyContent: "center",
    },
    position: "relative", // Make the tabs component the positioning context
  },
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100vh", // Full height of the viewport
    justifyContent: "space-between", // Title stays at the top, message input at the bottom
    transition: "margin 0.3s ease", // Smooth transition when the sidebars collapse
  },
  spinnerOverlay: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(255, 255, 255, 0.7)", // Semi-transparent background to indicate loading
    zIndex: 1000, // Ensures the spinner is on top of other content
  },
  blurredContent: {
    filter: "blur(5px)", // Apply a blur effect to the content
    pointerEvents: "none", // Disable interactions while loading
  },
  activeTab: {
    backgroundColor: "#e6f7ff",
  },
});

const WireframeDisplay = ({
  leftCollapsed,
  rightCollapsed,
  isNewUserStory,
  idFeature,
}) => {
  const classes = useStyles({ leftCollapsed, rightCollapsed });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const selectedWireframe = useSelector(
    (state) => state.selectedFeature.selectedWireframe
  );

  const { data: userStory, isFetching: isFetchingUserStory } =
    useGetUserStoryByIdQuery(selectedWireframe?.id, {
      skip: !selectedWireframe?.id || isNewUserStory, // Skip fetching if it's a new user story
    });
  const { data: projectsData, refetch: refetchProjects } =
    useGetAllProjectsQuery(); // Get and refetch project data

  const versions = [...(userStory?.versions || [])].sort(
    (a, b) => a.versionNumber - b.versionNumber
  );

  const [currentEpicIndex, setCurrentEpicIndex] = useState(0);
  const [currentFeatureIndex, setCurrentFeatureIndex] = useState(0);
  const [currentStoryIndex, setCurrentStoryIndex] = useState(0);
  const [status, setStatus] = useState("idle");
  const [versionId, setVersionId] = useState();
  const [activeTabKey, setActiveTabKey] = useState(
    versions.findIndex((v) => v.id === userStory?.currentVersion?.id).toString()
  );

  const [messageInput, setMessageInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [disableActions, setDisableActions] = useState(false);
  const [audioLoading, setAudioLoading] = useState(false);

  const [updateUserStoryVersion] = useUpdateUserStoryVersionMutation();
  const [setCurrentVersion] = useSetVersionAsCurrentVersionMutation();
  const [addUserStory] = useAddUserStoryMutation(); // AddUserStory mutation

  const { startRecording, stopRecording } = useReactMediaRecorder({
    audio: true,
    onStart: () => {
      setStatus("recording");
    },
    onStop: async (blobUrl) => {
      setStatus("processing");
      setAudioLoading(true);
      try {
        const response = await fetch(blobUrl);
        const blob = await response.blob();
        const file = new File([blob], "audio_recording.wav", {
          type: blob.type,
        });

        const transcriptResponse = await dispatch(
          sendAudioForTranscription(file)
        ).unwrap();

        const transcript = transcriptResponse?.files?.[0]?.transcript || "";
        setMessageInput(transcript);
        dispatch(setTranscript(transcript));
      } catch (error) {
        message.error("Failed to process the audio.");
      } finally {
        setAudioLoading(false);
        setStatus("idle");
      }
    },
  });

  const handleTabChange = (key) => {
    setActiveTabKey(key);
    setVersionId(Number(key));

    const selectedVersion = versions.find(
      (version) => version.id.toString() === key
    );

    if (selectedVersion) {
      setMessageInput(selectedVersion.description);
    }
  };
  const generateVersionHistory = (history) => {
    // Split the history string by the separator '---'
    const historyArray = history ? history.split("---") : [];

    // Map through the history array to return formatted history entries
    return historyArray
      .map((entry, index) => `Change ${index + 1}: ${entry.trim()}`)
      .join("\n"); // Join without extra separator, just newlines between changes
  };
  useEffect(() => {
    if (isNewUserStory) {
      setMessageInput(""); // Clear message input when it's a new user story
    } else if (userStory?.versions?.length > 0) {
      const sortedVersions = [...userStory.versions].sort(
        (a, b) => b.versionNumber - a.versionNumber
      );

      const newestVersion = sortedVersions[0];
      setActiveTabKey(newestVersion.id.toString());
      setMessageInput(newestVersion?.description);
      setVersionId(Number(newestVersion?.id));
    }
  }, [isNewUserStory, userStory]);

  useEffect(() => {
    if (userStory?.versions?.length > 0) {
      const sortedVersions = [...userStory.versions].sort(
        (a, b) => b.versionNumber - a.versionNumber
      );

      const newestVersion = sortedVersions[0];
      setActiveTabKey(newestVersion.id.toString());
      setMessageInput(newestVersion?.description);
      setVersionId(Number(newestVersion?.id));
    }
  }, [userStory]);

  const handleSetAsCurrentVersion = async (versionId) => {
    try {
      await setCurrentVersion({
        versionId,
        projectId: selectedWireframe?.project?.id,
      }).unwrap();
      message.success("Version set as current successfully.");

      dispatch(
        selectWireframe({
          ...selectedWireframe,
          currentVersion: versions.find((v) => v.id === versionId),
        })
      );
    } catch (error) {
      console.log({ error });

      message.error("Failed to set as current version.");
    }
  };

  const handleSend = async () => {
    if (!messageInput.trim()) return;

    setLoading(true);
    setDisableActions(true);

    try {
      if (isNewUserStory) {
        // If it's a new user story, call the addUserStory mutation
        const response = await addUserStory({
          newUserStoryDescription: messageInput,
          featureId: idFeature,
        }).unwrap();

        const { userStory } = response;

        message.success("User story created successfully!");

        // Set the newly created user story as selected in ProjectsLeft
        dispatch(
          selectWireframe({
            id: userStory?.id,
            title: userStory.title,
            description: userStory.description,
            wireFrame: userStory.wireFrame,
            projectId: userStory.projectId,
            project: {}, // Adjust this if necessary based on what you need for the project
          })
        );
        dispatch(setTranscript(userStory.projectDescription));

        // Refetch the projects data to update the project store
        refetchProjects();

        // Navigate to the newly created user story's page
        navigate(
          `/${i18n.language}/projects/${userStory.projectId}/${userStory.epicId}/${userStory.featureId}/${userStory.id}`
        );
      } else {
        // Update existing user story version
        await updateUserStoryVersion({
          versionId: versionId,
          newUserStoryDescription: messageInput,
        }).unwrap();

        message.success("User story updated successfully!");

        dispatch(
          selectWireframe({
            ...selectedWireframe,
            currentVersion: {
              ...selectedWireframe.currentVersion,
              description: messageInput,
            },
          })
        );
      }
    } catch (error) {
      message.error("Failed to save user story.");
    } finally {
      setLoading(false);
      setDisableActions(false);
    }
  };

  return (
    <div className="w-full mx-auto">
      {!isNewUserStory ? (
        <div className={classes.tabs_component}>
          {isFetchingUserStory && !isNewUserStory && (
            <div className={classes.spinnerOverlay}>
              <Spin size="large" />
            </div>
          )}
          <div className={isFetchingUserStory && classes.blurredContent}>
            <Title level={2} className="text-center">
              {userStory?.title || "No Wireframe Selected"}
            </Title>

            <Tabs
              activeKey={activeTabKey}
              className={classes.tabs_component}
              onChange={handleTabChange}
            >
              {versions.map((version, index) => (
                <TabPane
                  tab={
                    <span
                      className={
                        userStory.currentVersion?.id === version?.id
                          ? classes.activeTab
                          : ""
                      }
                    >
                      {version.versionNumber}
                      {/* Show History Button */}
                    </span>
                  }
                  key={`${version?.id}`}
                >
                  <div
                    style={{
                      border:
                        userStory?.currentVersion?.id === version?.id
                          ? "3px solid #1890ff"
                          : "none",
                      borderRadius: "5px",
                      padding: "10px",
                      margin: "10px",
                    }}
                  >
                    <WireframeViewer
                      showSetCurrentVersion={
                        userStory?.currentVersion?.id !== version?.id
                      }
                      versionId={versionId}
                      leftCollapsed={leftCollapsed}
                      rightCollapsed={rightCollapsed}
                      handleSetAsCurrentVersion={handleSetAsCurrentVersion}
                      wireFrame={version.wireFrame}
                    />
                  </div>

                  {/* Show History Button with dynamic positioning based on collapse state */}
                  <div className={classes.historyButtonWrapper}>
                    <Tooltip
                      title={
                        <div className={classes.historyTooltip}>
                          {generateVersionHistory(version.history)}
                        </div>
                      }
                      placement="top"
                    >
                      <Button
                        className="bg-black text-white border-black w-full m-2 hover:bg-[#5a5a5a]"
                        size="small"
                        type="primary"
                      >
                        Show History
                      </Button>
                    </Tooltip>
                  </div>

                  <MessageInput
                    messageInput={messageInput}
                    setMessageInput={setMessageInput}
                    handleSend={handleSend}
                    loading={loading}
                    disableActions={disableActions || audioLoading}
                    startRecording={startRecording}
                    stopRecording={stopRecording}
                    status={status}
                  />
                </TabPane>
              ))}
            </Tabs>
          </div>
        </div>
      ) : (
        <div className={classes.container}>
          <div className={classes.tabs_component_new_us}>
            <Title level={2} className="text-center">
              Create New User Story
            </Title>
          </div>

          <div className={classes.messageInputWrapper}>
            <MessageInput
              messageInput={messageInput}
              setMessageInput={setMessageInput}
              handleSend={handleSend}
              loading={loading}
              disableActions={disableActions || audioLoading}
              startRecording={startRecording}
              stopRecording={stopRecording}
              status={status}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default WireframeDisplay;
