import React from "react";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";
import ConfigurationComponent from "./components/ConfigurationComponsnt";
import Login from "./pages/Login";
import ProjectsRouter from "./ProjectsRouter";
import TranslationProvider from "./TransaltionProvider";
import Users from "./pages/Users";
import AddUsers from "./pages/AddUser";

const AppRouter = () => {
  const isLoggedIn = useSelector((state) => state.user?.user?.id);

  return (
    <TranslationProvider>
      <ConfigurationComponent />

      {!isLoggedIn ? (
        <Login />
      ) : (
        <Routes>
          {/* Use nested routes under `/en/projects` */}
          <Route path="/en/projects/*" element={<ProjectsRouter />} />
          <Route path="/en/login" element={<Login />} />
          <Route path="/en/user-management" element={<Users />} />
          <Route path="/en/new-user" element={<AddUsers />} />
          {/* Other routes can be added here */}
          <Route path="*" element={<ProjectsRouter />} />
        </Routes>
      )}
    </TranslationProvider>
  );
};

export default AppRouter;
