import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedWireframe: null, // This will store the HTML and CSS code for the wireframe
};

const selectedWireframeSlice = createSlice({
  name: "selectedWireframe",
  initialState,
  reducers: {
    selectWireframe: (state, action) => {
      state.selectedWireframe = action.payload; // Store the wireframe code here
    },
    clearSelectedWireframe: (state) => {
      state.selectedWireframe = null; // Clear the stored wireframe
    },
  },
});

export const { selectWireframe, clearSelectedWireframe } =
  selectedWireframeSlice.actions;

export default selectedWireframeSlice.reducer;
