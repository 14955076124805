import React from "react";
import { Layout } from "antd";
import { createUseStyles } from "react-jss";
import UsersMainContent from "../components/users/UsersMainContent"; // Ensure this component is correctly created
import UsersLeft from "../components/users/UsersLeft"; // Ensure this component is correctly created
import { useGetAllUsersQuery } from "../features/usersSlice";
import AddUsersMainContent from "../components/addUser/AddUsersMainContent";
import AddUsersLeft from "../components/addUser/AddUsersLeft";

const { Sider, Content } = Layout;

const useStyles = createUseStyles({
  left_sider: {
    "& .ant-layout-sider-children": {
      display: "flex",
    },
  },
});

const AddUsers = () => {
  const classes = useStyles();

  const { data, error, isLoading } = useGetAllUsersQuery();

  if (error) {
    return <div>Error loading users</div>;
  }

  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider
        width={250}
        className={classes.left_sider}
        style={{ backgroundColor: "#FFD700" }}
      >
        <AddUsersLeft />
      </Sider>

      {/* Main Content */}
      <Content style={{ padding: "0px" }}>
        <AddUsersMainContent />
      </Content>
    </Layout>
  );
};

export default AddUsers;
