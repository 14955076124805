import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  sidebarContainer: {
    height: "100vh",
    backgroundColor: "#FFD700",
    transition: "width 0.2s, left 0.2s",
    overflow: "hidden",
    zIndex: 1010,
    display: "flex",
    flexDirection: "column",
  },
  absolutePosition: {
    position: "absolute",
  },
  relativePosition: {
    position: "relative",
  },
  collapsedContainer: {
    width: "80px",
  },
  expandedContainer: {
    width: "250px",
  },
  menuContent: {
    flexGrow: 1,
    overflowY: "auto",
    backgroundColor: "#FFD700",
    padding: "10px 0",

    "& .ant-menu-item-selected": { backgroundColor: "#e1e3e2" },
    /* General Styling for Project Titles */
    "& .ant-menu-submenu-title": {
      backgroundColor: "#ffeb99" /* Light yellow for project titles */,
      fontSize: "14px" /* Adjust font size for project level */,
    },
    "& .ant-menu-submenu-title.ant-menu-submenu-open": {
      backgroundColor: "#ffe066" /* Slightly darker yellow when open */,
    },
    /* First Level (Project Level) */
    "& .ant-menu-sub": {
      backgroundColor:
        "#FFD700 !important" /* Light golden yellow for the first level (projects) */,
      "& .ant-menu-submenu-title": {
        backgroundColor:
          "#FFD700 !important" /* Same color for project submenu title */,
      },

      /* Second Level (Epic Level) */
      "& .ant-menu-submenu": {
        backgroundColor:
          "#dbb74c !important" /* More saturated yellow for epics */,
        "& .ant-menu-submenu-title": {
          backgroundColor: "#dbb74c !important" /* Epic submenu title color */,
        },

        /* Third Level (Feature Level) */
        "& .ant-menu-submenu": {
          backgroundColor:
            "#f0aa0a  !important" /* Slightly darker orange-yellow for features */,
          "& .ant-menu-submenu-title": {
            backgroundColor:
              "#f0aa0a  !important" /* Feature submenu title color */,
          },

          /* Fourth Level (User Story Level) */
          "& .ant-menu-submenu": {
            backgroundColor:
              "#FF9933 !important" /* Darker orange for user stories */,
            "& .ant-menu-submenu-title": {
              backgroundColor:
                "#FF9933 !important" /* User story submenu title color */,
            },
          },
        },
      },
    },

    /* Adjust Font Sizes for all levels */
    "& .ant-menu-item, & .ant-menu-submenu-title": {
      fontSize: "12px" /* Smaller font for all menu items */,
    },
  },
  bottomMenu: {
    backgroundColor: "#FFD700",
  },
  collapseButton: {
    position: "absolute",
    bottom: "20px",
    left: "50%",
    transform: "translateX(-50%)",
    zIndex: 1011,
    backgroundColor: "#FFD700",
    borderColor: "#FFD700",
    color: "black",
  },
  bottomSection: {
    paddingTop: "10px",
    backgroundColor: "#FFD700", // Yellow background color
  },

  childMenuItem: {
    color: "#554b15 !important", // Same font color as parent items
    fontWeight: "bold", // Same font weight as parent items
    fontSize: "14px", // Same font size as parent items
  },
  menuIcon: {
    width: "16px",
    height: "16px",
  },
  customButton: {
    backgroundColor: "black",
    color: "white",
    borderColor: "black",
    width: "100%",
    marginBottom: "8px",
    "&:hover": {
      backgroundColor: "#FFD700", // Your site hover color (gold/yellow)
      color: "black", // Text color change on hover
      borderColor: "#FFD700", // Border color change on hover
    },
  },
  newStoryButton: {
    padding: "0 !important",

    "& span button ": {
      backgroundColor: "black",
      color: "white",
      width: "100%",
    },
  },
  "& .ant-menu-title-content": {
    border: "1px red",
  },
});

export default useStyles;
