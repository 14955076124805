import React from "react";
import { Form, Input, Button, Typography, Tooltip, notification } from "antd";
import {
  PhoneOutlined,
  MailOutlined,
  UserOutlined,
  LockOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { useCreateUserMutation } from "../../features/usersSlice";
import "tailwindcss/tailwind.css";

const { Title } = Typography;

const AddUsersMainContent = () => {
  const [form] = Form.useForm();
  const [createUser, { isLoading }] = useCreateUserMutation();

  const onFinish = async (values) => {
    try {
      const res = await createUser({
        email: values.email,
        userName: values.userName,
        password: values.password,
        phoneNumber: values.phoneNumber || null,
      }).unwrap();

      if (res.success) {
        notification.success({
          message: "User Created Successfully",
          description: `User ${values.userName} has been created.`,
        });

        form.resetFields(); // Reset form after successful submission
      } else {
        notification.error({
          message: "Error",
          description:
            res.message ||
            "There was an error creating the user. Please try again.",
        });
      }
    } catch (error) {
      notification.error({
        message: "Error",
        description: "There was an error creating the user. Please try again.",
      });
    }
  };

  const validatePassword = (_, value) => {
    if (!value) {
      return Promise.reject(new Error("Please input your password!"));
    }
    if (value.length < 8) {
      return Promise.reject(
        new Error("Password must be at least 8 characters!")
      );
    }
    if (!/[A-Z]/.test(value)) {
      return Promise.reject(
        new Error("Password must include at least one uppercase letter!")
      );
    }
    if (!/[a-z]/.test(value)) {
      return Promise.reject(
        new Error("Password must include at least one lowercase letter!")
      );
    }
    if (!/[0-9]/.test(value)) {
      return Promise.reject(
        new Error("Password must include at least one number!")
      );
    }
    if (!/[!@#$%^&*]/.test(value)) {
      return Promise.reject(
        new Error("Password must include at least one special character!")
      );
    }
    return Promise.resolve();
  };

  const validateConfirmPassword = ({ getFieldValue }) => ({
    validator(_, value) {
      if (!value || getFieldValue("password") === value) {
        return Promise.resolve();
      }
      return Promise.reject(new Error("The two passwords do not match!"));
    },
  });

  const validatePhoneNumber = (_, value) => {
    if (!value) {
      return Promise.reject(new Error("Please input your phone number!"));
    }
    const phoneRegex = /^\+?[0-9]{10,15}$/;
    if (!phoneRegex.test(value)) {
      return Promise.reject(
        new Error(
          "Phone number must be valid and contain only digits (10-15 digits)."
        )
      );
    }
    return Promise.resolve();
  };

  const validateUserName = (_, value) => {
    if (!value) {
      return Promise.reject(new Error("Please input your username!"));
    }
    const userNameRegex = /^[a-zA-Z0-9_]{3,20}$/;
    if (!userNameRegex.test(value)) {
      return Promise.reject(
        new Error(
          "Username must be 3-20 characters long and can include letters, numbers, and underscores."
        )
      );
    }
    return Promise.resolve();
  };

  return (
    <div className="p-8 flex flex-col items-center bg-gray-100 min-h-screen">
      <div className="bg-white rounded-lg shadow-lg p-6 w-full max-w-md mb-8">
        <Title level={3} className="text-center mb-6">
          Add User
        </Title>
        <Form
          form={form}
          name="addUser"
          layout="vertical"
          onFinish={onFinish}
          initialValues={{
            email: "",
            userName: "",
            phoneNumber: "",
            password: "",
            confirmPassword: "",
          }}
        >
          <Form.Item
            label={
              <span>
                <MailOutlined className="mr-2" />
                Email
                <Tooltip title="Please enter a valid email address (e.g., user@example.com).">
                  <InfoCircleOutlined className="ml-2 text-gray-400" />
                </Tooltip>
              </span>
            }
            name="email"
            rules={[
              {
                type: "email",
                message: "The input is not a valid email!",
              },
              {
                required: true,
                message: "Please input your email!",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={
              <span>
                <UserOutlined className="mr-2" />
                Username
                <Tooltip title="Username must be 3-20 characters long and can include letters, numbers, and underscores.">
                  <InfoCircleOutlined className="ml-2 text-gray-400" />
                </Tooltip>
              </span>
            }
            name="userName"
            rules={[
              {
                required: true,
                validator: validateUserName,
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={
              <span>
                <PhoneOutlined className="mr-2" />
                Phone Number
                <Tooltip title="Phone number must be 10-15 digits long and contain only numbers.">
                  <InfoCircleOutlined className="ml-2 text-gray-400" />
                </Tooltip>
              </span>
            }
            name="phoneNumber"
            rules={[
              {
                required: true,
                validator: validatePhoneNumber,
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={
              <span>
                <LockOutlined className="mr-2" />
                Password
                <Tooltip title="Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one number, and one special character (!@#$%^&*).">
                  <InfoCircleOutlined className="ml-2 text-gray-400" />
                </Tooltip>
              </span>
            }
            name="password"
            rules={[
              {
                required: true,
                validator: validatePassword,
              },
            ]}
            hasFeedback
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            label={
              <span>
                <LockOutlined className="mr-2" />
                Confirm Password
              </span>
            }
            name="confirmPassword"
            dependencies={["password"]}
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please confirm your password!",
              },
              validateConfirmPassword(form),
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" block loading={isLoading}>
              Add User
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default AddUsersMainContent;
