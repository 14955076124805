import React, { useState, useEffect, useRef } from "react";
import { Typography, Button } from "antd";
import { ResizableBox } from "react-resizable";
import "react-resizable/css/styles.css"; // Import the default styles for the resizable box

const { Paragraph } = Typography;

const WireframeViewer = ({
  wireFrame,
  handleSetAsCurrentVersion,
  versionId,
  showSetCurrentVersion,
  leftCollapsed,
  rightCollapsed,
}) => {
  // Initial width calculation
  const calculateInitialWidth = () => {
    return window.innerWidth - (!leftCollapsed && !rightCollapsed ? 605 : 400);
  };

  const [width, setWidth] = useState(calculateInitialWidth());
  const [height, setHeight] = useState(window.innerHeight - 350);
  const [maxWidth, setMaxWidth] = useState(window.innerWidth);
  const [maxHeight, setMaxHeight] = useState(window.innerHeight - 50);

  const containerRef = useRef(null);

  useEffect(() => {
    const updateMaxConstraints = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.offsetWidth;
        const containerHeight =
          window.innerHeight - containerRef.current.offsetTop - 50; // 50px padding from bottom
        setMaxWidth(containerWidth);
        setMaxHeight(containerHeight);

        // Adjust width based on the sidebar states
        setWidth(
          window.innerWidth - (!leftCollapsed && !rightCollapsed ? 605 : 400)
        );
      }
    };

    updateMaxConstraints();
    window.addEventListener("resize", updateMaxConstraints);

    return () => window.removeEventListener("resize", updateMaxConstraints);
  }, [leftCollapsed, rightCollapsed]);

  return (
    <div ref={containerRef} className="relative w-full flex justify-center">
      <ResizableBox
        width={width}
        height={height}
        minConstraints={[300, 200]}
        maxConstraints={[maxWidth, maxHeight]}
        className="bg-white p-5 mb-5 rounded-lg shadow-md"
        resizeHandles={["se"]} // Bottom-right corner resize handle
        onResizeStop={(e, data) => {
          setWidth(data.size.width);
          setHeight(data.size.height);
        }}
      >
        {wireFrame ? (
          <iframe
            title="Wireframe Viewer"
            srcDoc={wireFrame}
            sandbox="allow-scripts allow-same-origin"
            className="w-full h-full border-none"
          />
        ) : (
          <Paragraph>
            This is where the wireframe content will be displayed.
          </Paragraph>
        )}
      </ResizableBox>
      {showSetCurrentVersion && (
        <Button
          type="default"
          style={{ top: "-66px" }}
          className="font-medium text-lg border ml-10 absolute left-6"
          onClick={() => {
            handleSetAsCurrentVersion(versionId);
          }}
        >
          Set as Current Version
        </Button>
      )}
    </div>
  );
};

export default WireframeViewer;
