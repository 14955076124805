import React, { useState } from "react";
import { Table, Typography, Tooltip, Button, Modal } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import "tailwindcss/tailwind.css";
import { truncateText } from "../../helper/utils";

const { Title } = Typography;

const UserProjectsTable = ({ projects, isLoadingProjects }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentDescription, setCurrentDescription] = useState("");

  const showModal = (description) => {
    setCurrentDescription(description);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 80, // Set a smaller width for Project ID
      align: "center", // Center the text in this column
      render: (text) => (
        <div className="whitespace-nowrap overflow-hidden text-ellipsis">
          {text}
        </div>
      ),
    },
    {
      title: "Project Title",
      dataIndex: "title",
      key: "title",
      align: "center", // Center the text in this column
      render: (text) => (
        <div className="whitespace-nowrap overflow-hidden text-ellipsis">
          {text}
        </div>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      align: "center", // Center the text in this column
      render: (text) => (
        <div className="whitespace-nowrap overflow-hidden text-ellipsis">
          {text.length > 50 ? (
            <Tooltip title={text}>
              {truncateText(text, 30)}
              <Button
                type="link"
                icon={<EyeOutlined />}
                onClick={() => showModal(text)}
                style={{ paddingLeft: "8px" }}
              >
                View More
              </Button>
            </Tooltip>
          ) : (
            text
          )}
        </div>
      ),
    },
  ];

  return (
    <div className="bg-white rounded-lg shadow-lg p-4 w-full max-w-3xl mx-auto">
      <Title level={4} className="text-center mb-2">
        User Projects
      </Title>
      <Table
        dataSource={projects}
        columns={columns}
        rowKey="id"
        pagination={{ pageSize: 5 }} // Keep pagination with 5 items per page
        loading={isLoadingProjects}
        className="text-center" // Center all table content
      />
      <Modal
        title="Project Description"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="ok" type="primary" onClick={handleOk}>
            Close
          </Button>,
        ]}
      >
        <p>{currentDescription}</p>
      </Modal>
    </div>
  );
};

export default UserProjectsTable;
