import React from "react";
import { Layout } from "antd";
import { createUseStyles } from "react-jss";
import ProjectsMainContent from "../components/ProjectsMainContent";

const { Content } = Layout;

const useStyles = createUseStyles({
  mainContent: {
    padding: "0px",
  },
});

const Projects = () => {
  const classes = useStyles();
  console.log("rendered projects");

  return (
    <Layout
      style={{
        minHeight: "100vh",
        position: "relative",
        display: "flex",
        flexDirection: "row",
      }}
    >
      <Content className={classes.mainContent}>
        <ProjectsMainContent />
      </Content>
    </Layout>
  );
};

export default Projects;
