// src/App.jsx
import React, { Suspense } from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import store from "./store";
import AppRouter from "./AppRouter"; // Import the AppRouter component

function App() {
  return (
    <Suspense fallback={<>loading</>}>
      <Provider store={store}>
        <Router>
          <AppRouter /> {/* AppRouter is now within the Router context */}
        </Router>
      </Provider>
    </Suspense>
  );
}

export default App;
