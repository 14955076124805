import { LogoutOutlined, TeamOutlined, UserOutlined } from "@ant-design/icons";
import { Button, message } from "antd";
import i18n from "i18next";
import React, { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useGetUserProjectsQuery } from "../../features/projectsSlice"; // Import the hook to fetch user's projects
import { clearUser } from "../../features/userSlice";
import { useGetUserByIdQuery } from "../../features/usersSlice"; // Import the hook to fetch user by ID

const useStyles = createUseStyles({
  left_sid_bar: {
    "& .ant-layout-sider-children": {
      display: "flex",
      flexDirection: "column",
    },
  },
});

const AddUsersLeft = () => {
  const [userId, setUserId] = useState(null);
  const { data: user, error: userError } = useGetUserByIdQuery(userId, {
    skip: !userId,
  });
  const { data: projects, error: projectError } = useGetUserProjectsQuery(
    userId,
    { skip: !userId }
  );

  const users = useSelector(
    (state) =>
      state.usersService.queries["getAllUsers(undefined)"]?.data?.users || []
  );

  const navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(clearUser()); // Clears user state
    dispatch({ type: "resetState" }); // Resets all states
    navigate(`/${i18n.language}/login`); // Redirect to login page
  };

  const handleUserClick = (userId) => {
    if (userId) setUserId(userId);
  };

  useEffect(() => {
    if (userError) {
      message.error("Failed to fetch user details.");
    }
    if (projectError) {
      message.error("Failed to fetch user projects.");
    }
  }, [userError, projectError]);

  return (
    <div
      className={classes.left_sid_bar}
      style={{
        width: 250,
        backgroundColor: "#FFD700", // Yellow background
        padding: "20px 0px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {/* Top Section */}
      <div style={{ flexGrow: 1, padding: "0px 20px" }}>
        <Button
          type="primary"
          block
          style={{
            backgroundColor: "#000",
            marginBottom: "20px",
            fontWeight: "600",
            lineHeight: "20px",
            fontSize: "14px",
            fontFamily: "Inter",
          }}
          onClick={() => navigate("/projects")}
        >
          ← Projects
        </Button>
      </div>

      {/* Bottom Section */}
      <div
        className="bottom_section"
        style={{
          width: "100%",
          borderTop: "solid 1px #a59d9d",
          padding: "0px 15px",
        }}
      >
        {/* User Management Button */}
        {
          <Button
            icon={<TeamOutlined />}
            block
            style={{
              textAlign: "left",
              padding: "0px !important",
              marginTop: "10px",
              width: "100%",
              border: "none",
              boxShadow: "none",
              backgroundColor: "#ffd700",
              color: "#1C1C1C",
              fontWeight: "600",
              lineHeight: "20px",
              fontSize: "14px",
              fontFamily: "Inter",
            }}
            onClick={() => navigate("/user-management")}
          >
            User Management
          </Button>
        }

        {/* My Account Button */}
        <Button
          icon={<UserOutlined />}
          block
          style={{
            textAlign: "left",
            padding: "0px !important",
            marginTop: "10px",
            width: "100%",
            border: "none",
            boxShadow: "none",
            backgroundColor: "#ffd700",
            color: "#1C1C1C",
            fontWeight: "600",
            lineHeight: "20px",
            fontSize: "14px",
            fontFamily: "Inter",
          }}
          onClick={() => navigate("/#")}
        >
          My account
        </Button>

        {/* Logout Button */}
        <Button
          icon={<LogoutOutlined />}
          block
          style={{
            textAlign: "left",
            padding: "1px !important",
            width: "100%",
            backgroundColor: "#ffd701",
            border: "none",
            boxShadow: "none",
            color: "#1C1C1C",
            fontWeight: "600",
            lineHeight: "20px",
            fontSize: "14px",
            fontFamily: "Inter",
          }}
          onClick={handleLogout}
        >
          Log out
        </Button>
      </div>
    </div>
  );
};

export default AddUsersLeft;
