import React, { useState, useEffect } from "react";
import { Input, Typography, Skeleton, Switch, Button, Form, Modal } from "antd";
import {
  PhoneOutlined,
  MailOutlined,
  UserOutlined,
  LockOutlined,
} from "@ant-design/icons";
import "tailwindcss/tailwind.css";

const { Title, Text } = Typography;

const UserInformation = ({
  user,
  isLoadingUser,
  onUpdateUser,
  onUpdatePassword,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [passwordForm] = Form.useForm();

  // Initialize form fields when the component mounts
  useEffect(() => {
    if (user) {
      form.setFieldsValue({
        userName: user.userName,
        phoneNumber: user.phoneNumber,
        isActive: user.isActive,
      });
    }
  }, [user, form]);

  const toggleEdit = () => {
    setIsEditing(!isEditing);
    if (!isEditing) {
      form.setFieldsValue({
        userName: user.userName,
        phoneNumber: user.phoneNumber,
        isActive: user.isActive,
      });
    }
  };

  const handleUpdateUser = async () => {
    const values = await form.validateFields();

    onUpdateUser({
      ...user,
      userName: values.userName,
      phoneNumber: values.phoneNumber,
      isActive: values.isActive,
    });
    setIsEditing(false);
  };

  const showUpdatePasswordModal = () => {
    setIsModalVisible(true);
  };

  const handleUpdatePassword = async () => {
    try {
      const values = await passwordForm.validateFields();
      onUpdatePassword(values.password);
      setIsModalVisible(false);
      passwordForm.resetFields();
    } catch (error) {
      console.error("Failed to update password:", error);
    }
  };

  const validatePassword = (_, value) => {
    if (!value || value.length < 8) {
      return Promise.reject(
        new Error("Password must be at least 8 characters long")
      );
    }
    if (!/[A-Z]/.test(value)) {
      return Promise.reject(
        new Error("Password must contain at least one uppercase letter")
      );
    }
    if (!/[a-z]/.test(value)) {
      return Promise.reject(
        new Error("Password must contain at least one lowercase letter")
      );
    }
    if (!/[0-9]/.test(value)) {
      return Promise.reject(
        new Error("Password must contain at least one number")
      );
    }
    if (!/[!@#$%^&*]/.test(value)) {
      return Promise.reject(
        new Error("Password must contain at least one special character")
      );
    }
    return Promise.resolve();
  };

  return (
    <div className="bg-white rounded-lg shadow-lg p-4 w-full max-w-md mb-4">
      {isLoadingUser ? (
        <div>
          <Title level={4} className="text-center mb-2">
            Select User to View!
          </Title>
          <Skeleton />
        </div>
      ) : (
        <>
          <Title level={4} className="text-center mb-2">
            User Information
          </Title>

          <Form
            form={form}
            layout="vertical"
            initialValues={{ isActive: user.isActive }}
          >
            <Form.Item label="Email" className="mb-2">
              <Input
                prefix={<MailOutlined className="mr-2" />}
                value={user.email}
                readOnly
                disabled
                className="w-full p-1 border rounded bg-gray-200 cursor-not-allowed"
              />
            </Form.Item>

            <Form.Item
              label="Username"
              name="userName"
              rules={[{ required: true, message: "Username is required" }]}
              className="mb-2"
            >
              <Input
                prefix={<UserOutlined className="mr-2" />}
                readOnly={!isEditing}
                className={`w-full p-1 border rounded ${
                  isEditing
                    ? "bg-white cursor-text"
                    : "bg-gray-100 cursor-not-allowed"
                }`}
              />
            </Form.Item>

            <Form.Item
              label="Phone Number"
              name="phoneNumber"
              rules={[{ required: true, message: "Phone number is required" }]}
              className="mb-2"
            >
              <Input
                prefix={<PhoneOutlined className="mr-2" />}
                readOnly={!isEditing}
                className={`w-full p-1 border rounded ${
                  isEditing
                    ? "bg-white cursor-text"
                    : "bg-gray-100 cursor-not-allowed"
                }`}
              />
            </Form.Item>
            <Form.Item
              name="isActive"
              valuePropName="checked"
              label="Active"
              className="flex items-center mb-2"
            >
              <Switch disabled={!isEditing} />
            </Form.Item>

            {isEditing ? (
              <div className="flex justify-end mt-2">
                <Button type="primary" onClick={handleUpdateUser}>
                  Update User
                </Button>
                <Button type="link" onClick={toggleEdit}>
                  Cancel
                </Button>
              </div>
            ) : (
              <div className="flex justify-end mt-2">
                <Button type="primary" onClick={toggleEdit}>
                  Edit
                </Button>
                <Button type="link" onClick={showUpdatePasswordModal}>
                  Update Password
                </Button>
              </div>
            )}
          </Form>
        </>
      )}

      {/* Update Password Modal */}
      <Modal
        title="Update Password"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
      >
        <Form
          form={passwordForm}
          layout="vertical"
          onFinish={handleUpdatePassword}
        >
          <Form.Item
            label="New Password"
            name="password"
            rules={[
              { required: true, message: "Please enter a new password" },
              { validator: validatePassword },
            ]}
            className="mb-2"
          >
            <Input.Password prefix={<LockOutlined />} />
          </Form.Item>
          <Form.Item
            label="Confirm Password"
            name="confirmPassword"
            dependencies={["password"]}
            hasFeedback
            rules={[
              { required: true, message: "Please confirm your password" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("The two passwords that you entered do not match")
                  );
                },
              }),
            ]}
            className="mb-2"
          >
            <Input.Password prefix={<LockOutlined />} />
          </Form.Item>
          <Text type="secondary" className="block mb-2">
            Password must be at least 8 characters long, contain at least one
            uppercase letter, one lowercase letter, one number, and one special
            character (!@#$%^&*).
          </Text>
          <div className="flex justify-end">
            <Button type="primary" htmlType="submit">
              Update Password
            </Button>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default UserInformation;
