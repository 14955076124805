import { Button, Card, Input, message, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RightOutlined, LeftOutlined } from "@ant-design/icons";
import { setTranscript } from "../features/transcriptSlice";
import { createUseStyles } from "react-jss";

const { TextArea } = Input;
const { Title } = Typography;

const useStyles = createUseStyles({
  sidebarContainer: {
    height: "calc(100vh - 64px)", // Account for the header height (64px)
    backgroundColor: "#e5e7eb",
    transition: "width 0.2s ease-in-out, right 0.3s ease-in-out",
    overflow: "hidden",
    zIndex: 1000,
    display: "flex",
    flexDirection: "column",
    top: "64px", // Start below the header
  },
  absolutePosition: {
    position: "absolute",
    right: 0, // Align to the right
  },
  relativePosition: {
    position: "relative",
  },
  collapsedContainer: {
    width: "48px", // Adjust the width when collapsed
  },
  expandedContainer: {
    width: "256px", // Adjust the width when expanded
  },
  hiddenText: {
    visibility: "hidden",
  },
  visibleText: {
    visibility: "visible",
  },
});

const RightSidebar = ({ isCollapsed, setIsCollapsed }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const transcript = useSelector((state) => state.transcript?.transcript);
  const [editableTranscript, setEditableTranscript] = useState("");
  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    setEditableTranscript(transcript);
  }, [transcript]);

  const handleUpdate = async () => {
    try {
      dispatch(setTranscript(editableTranscript));
      message.success("Transcript updated successfully!");
    } catch (error) {
      message.error("Failed to update transcript.");
    }
  };

  const handleMouseEnter = () => {
    if (isCollapsed) setHovered(true);
  };

  const handleMouseLeave = () => {
    if (isCollapsed) setHovered(false);
  };

  return (
    <aside
      className={`${classes.sidebarContainer} ${
        isCollapsed && classes.absolutePosition
      } ${
        isCollapsed && !hovered
          ? classes.collapsedContainer
          : classes.expandedContainer
      }`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="flex items-center p-2 bg-gray-200">
        <Button
          icon={isCollapsed ? <LeftOutlined /> : <RightOutlined />}
          shape="circle"
          onClick={() => setIsCollapsed(!isCollapsed)}
          className="bg-gray-800 text-white w-8 h-8 flex items-center justify-center rounded-full"
        />
        <div
          className={`${
            isCollapsed && !hovered ? classes.hiddenText : classes.visibleText
          }`}
        >
          {!isCollapsed && (
            <Title className="ml-2 mb-0" level={4}>
              Transcript
            </Title>
          )}
        </div>
      </div>

      {(!isCollapsed || hovered) && (
        <Card className="m-2">
          <TextArea
            rows={10}
            value={editableTranscript}
            onChange={(e) => setEditableTranscript(e.target.value)}
            placeholder="Edit here..."
            className="mt-2"
          />
          <Button
            type="primary"
            onClick={handleUpdate}
            className="mt-2 bg-blue-500 hover:bg-blue-600"
          >
            Update
          </Button>
        </Card>
      )}
    </aside>
  );
};

export default RightSidebar;
