import {
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import { Button, Divider, Menu, Tooltip } from "antd";
import i18n from "i18next";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useGetAllProjectsQuery } from "../../features/projectsSlice"; // Adjust the import path as needed
import { selectWireframe } from "../../features/selectedFeatureSlice";
import { setTranscript } from "../../features/transcriptSlice";
import { clearUser } from "../../features/userSlice";
import { truncateText, sortByKey, renderProjectItems } from "./utils";
import useStyles from "./styles";

const ProjectsLeft = ({
  id,
  idEpic,
  idFeature,
  idUserStory,
  collapsed,
  setCollapsed,
}) => {
  const { data: projectsData } = useGetAllProjectsQuery();
  console.log("rendered ");

  const [hovered, setHovered] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState(id || null);
  const [selectedEpicId, setSelectedEpicId] = useState(idEpic || null);
  const [selectedFeatureId, setSelectedFeatureId] = useState(idFeature || null);
  const [selectedStoryId, setSelectedStoryId] = useState(idUserStory || null);
  const [openKeys, setOpenKeys] = useState([]);

  const selectedWireframe = useSelector(
    (state) => state.selectedFeature.selectedWireframe
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    // When the page loads, set the transcript with the selected project's description
    if (selectedProjectId) {
      const project = projectsData?.projects.find(
        (project) => project.id === Number(selectedProjectId)
      );
      if (project) {
        dispatch(setTranscript(project.description));
      }
    }
  }, [projectsData, selectedProjectId]);

  useEffect(() => {
    if ((!collapsed || hovered) && id) {
      const newOpenKeys = [
        `project_${id}`,
        idEpic ? `epic_${idEpic}` : null,
        idFeature ? `feature_${idFeature}` : null,
      ].filter(Boolean);
      setOpenKeys(newOpenKeys);
    } else setOpenKeys([]);
  }, [collapsed, hovered]);

  useEffect(() => {
    if (
      selectedWireframe?.projectId &&
      selectedWireframe?.epicId &&
      selectedWireframe?.featureId
    ) {
      const newOpenKeys = [
        `project_${selectedWireframe.projectId}`,
        `epic_${selectedWireframe.epicId}`,
        `feature_${selectedWireframe.featureId}`,
      ].filter(Boolean);
      if (!collapsed || hovered) setOpenKeys(newOpenKeys);
    }
  }, [selectedWireframe]);

  const handleSelectStory = ({ story, projectId, featureId, epicId }) => {
    setSelectedProjectId(projectId);
    setSelectedFeatureId(featureId);
    setSelectedEpicId(epicId);
    setSelectedStoryId(story.id);
    const project = projectsData?.projects.find(
      (project) => project.id === projectId
    );

    // Always set the transcript with the project's description
    if (project) {
      dispatch(setTranscript(project.description));
    }

    dispatch(
      selectWireframe({
        id: story?.id,
        title: story.title,
        description: story.description,
        wireFrame: story.wireFrame,
        projectId: projectId,
        project: project,
      })
    );

    navigate(
      `/${i18n.language}/projects/${projectId}/${epicId}/${featureId}/${story.id}`
    );
  };

  const handleNewStory = ({ story, projectId, featureId, epicId }) => {
    setSelectedProjectId(projectId);
    setSelectedFeatureId(featureId);
    setSelectedEpicId(epicId);
    setSelectedStoryId(story.id);
    const project = projectsData?.projects.find(
      (project) => project.id === projectId
    );

    // Always set the transcript with the project's description
    if (project) {
      dispatch(setTranscript(project.description));
    }

    dispatch(
      selectWireframe({
        id: story?.id,
        title: story.title,
        description: story.description,
        wireFrame: story.wireFrame,
        projectId: projectId,
        project: project,
      })
    );

    navigate(
      `/${i18n.language}/projects/${projectId}/${epicId}/${featureId}/${story.id}`
    );
  };

  useEffect(() => {
    if (
      selectedStoryId &&
      selectedProjectId &&
      selectedEpicId &&
      selectedFeatureId
    ) {
      const project = projectsData?.projects.find(
        (proj) => proj.id === Number(selectedProjectId)
      );

      const epic = project?.epics.find(
        (ep) => ep.id === Number(selectedEpicId)
      );

      const feature = epic?.features.find(
        (feat) => feat.id === Number(selectedFeatureId)
      );

      const story = feature?.userStories.find(
        (us) => us.id === Number(selectedWireframe?.id)
      );

      if (story) {
        handleSelectStory({
          story,
          projectId: selectedProjectId,
          epicId: selectedEpicId,
          featureId: selectedFeatureId,
        });
      }
    }
  }, [
    projectsData,
    selectedStoryId,
    selectedProjectId,
    selectedEpicId,
    selectedFeatureId,
  ]);

  const handleLogout = () => {
    dispatch(clearUser());
    dispatch({ type: "resetState" });
    navigate(`/${i18n.language}/login`);
  };

  const bottomMenuItems = [
    {
      key: "user-management",
      icon: <TeamOutlined style={{ color: "#554b15" }} />,
      label: (
        <span className="text-black font-semibold text-sm">
          {" "}
          User Management
        </span>
      ),
      onClick: () => navigate(`/${i18n.language}/user-management`),
    },
    {
      key: "my-account",
      icon: <UserOutlined style={{ color: "#554b15" }} />,
      label: (
        <span className="text-black font-semibold text-sm"> My Account</span>
      ),
      onClick: () => navigate("/#"),
    },
    {
      key: "logout",
      icon: <LogoutOutlined style={{ color: "#554b15" }} />,
      label: <span className="text-black font-semibold text-sm"> Log Out</span>,
      onClick: handleLogout,
    },
  ];

  const onOpenChange = (keys) => {
    setOpenKeys(keys);
  };

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
    setHovered(false);
  };

  const handleMouseEnter = () => {
    if (collapsed) setHovered(true);
  };

  const handleMouseLeave = () => {
    if (collapsed) setHovered(false);
  };

  const sortedProjects = (projectsData?.projects || [])
    .map((project) => ({
      ...project,
      epics: sortByKey(project.epics, "id", "asc").map((epic) => ({
        ...epic,
        features: sortByKey(epic.features, "id", "asc").map((feature) => ({
          ...feature,
          userStories: sortByKey(feature.userStories, "id", "asc"),
        })),
      })),
    }))
    .sort((a, b) => b.id - a.id);

  return (
    <div
      className={`${classes.sidebarContainer} ${
        collapsed ? classes.absolutePosition : classes.relativePosition
      } ${
        collapsed && !hovered
          ? classes.collapsedContainer
          : classes.expandedContainer
      }`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="flex flex-row mt-2 mx-2">
        <Button
          type="primary"
          className="bg-black text-white border-black w-full mb-2"
          onClick={() => navigate(`/${i18n.language}/projects`)}
        >
          + {hovered || !collapsed ? "New POC" : ""}
        </Button>
        {(hovered || !collapsed) && (
          <Button
            className="ml-2 bg-yellow-400 border-yellow-400 text-black"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={toggleCollapse}
          />
        )}
      </div>

      <Menu
        mode="inline"
        openKeys={openKeys}
        theme="light"
        inlineCollapsed={collapsed && !hovered}
        onOpenChange={onOpenChange}
        className={classes.menuContent}
        selectedKeys={[`story_${selectedStoryId}`].filter(Boolean)}
      >
        {sortedProjects.length &&
          renderProjectItems({
            projects: sortedProjects,
            selectedProjectId,
            selectedEpicId,
            selectedFeatureId,
            selectedStoryId,
            classes,
            handleSelectStory,
            handleNewStory,
            navigate,
          })}
      </Menu>

      <div className={classes.bottomMenu}>
        <Divider className="m-0" />
        <Menu
          mode="inline"
          theme="light"
          inlineCollapsed={collapsed && !hovered}
          items={bottomMenuItems}
          style={{
            backgroundColor: "#FFD700",
            color: "#554b15",
          }}
          className={classes.bottomSection}
        />
      </div>
    </div>
  );
};

export default ProjectsLeft;
