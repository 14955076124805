import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = getState()?.user?.token;
    if (token) {
      headers.set("token", token);
    }
    return headers;
  },
});

export const userStoryService = createApi({
  reducerPath: "userStoryService",
  baseQuery,
  tagTypes: ["UserStory"],
  endpoints: (builder) => ({
    updateUserStoryVersion: builder.mutation({
      query: ({ versionId, newUserStoryDescription }) => ({
        url: `/user/story/${versionId}/update/version`,
        method: "POST",
        body: { newUserStoryDescription },
      }),
      invalidatesTags: ["UserStory"],
    }),
    deleteUserStory: builder.mutation({
      query: ({ storyId }) => ({
        url: `/user/story/${storyId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["UserStory"],
    }),
    setVersionAsCurrentVersion: builder.mutation({
      query: ({ versionId, projectId }) => {
        return {
          url: `/user/story/setCurrentVersion`,
          method: "POST",
          body: { versionId },
        };
      },
      invalidatesTags: (result, error, { projectId }) => [
        "UserStory",
        { type: "ProjectId", id: projectId }, // This will refetch the project with the given ID
      ],
    }),
    addUserStory: builder.mutation({
      query: ({ newUserStoryDescription, featureId }) => ({
        url: `/user/story`,
        method: "POST",
        body: { newUserStoryDescription, featureId },
      }),
      invalidatesTags: ["UserStory"],
    }),
    getUserStoryById: builder.query({
      query: (storyId) => ({
        url: `/user/story/${storyId}`,
        method: "GET",
      }),
      providesTags: (result, error, storyId) => [
        { type: "UserStory", id: storyId },
      ],
      transformResponse: (response) => response?.userStory,
    }),
  }),
});

export const {
  useUpdateUserStoryVersionMutation,
  useDeleteUserStoryMutation,
  useSetVersionAsCurrentVersionMutation,
  useAddUserStoryMutation,
  useGetUserStoryByIdQuery, // Export the new query hook
} = userStoryService;
