import React, { useState } from "react";
import { Route, Routes, useParams, useLocation } from "react-router-dom";
import Projects from "./pages/Projects";
import ProjectDetails from "./pages/ProjectDetails";
import ProjectsLeft from "./components/ProjectsLeft/ProjectsLeft";
import { Layout } from "antd";
import ProjectsTable from "./../src/pages/ProjectsTable";

const ProjectsRouter = () => {
  const location = useLocation();
  const params = useParams(); // Capture all parameters
  const [leftCollapsed, setLeftCollapsed] = useState(true);
  const [rightCollapsed, setRightCollapsed] = useState(true);

  // Capture all parameters if they exist
  const [id, idEpic, idFeature, idUserStory] = params["*"]?.split("/") || [];
  console.log("rendered project router");

  // Check if the user story is a new one
  const isNewUserStory = idUserStory === "new";

  return (
    <Layout
      style={{
        minHeight: "100vh",
        position: "relative",
        display: "flex",
        flexDirection: "row",
      }}
    >
      {/* Pass route parameters to ProjectsLeft */}
      <ProjectsLeft
        collapsed={leftCollapsed}
        setCollapsed={setLeftCollapsed}
        id={id} // Pass the id param
        idEpic={idEpic} // Pass the idEpic param
        idFeature={idFeature} // Pass the idFeature param
        idUserStory={idUserStory} // Pass the idUserStory param
      />

      <Layout.Content
        style={{
          flexGrow: 1,
          marginLeft: leftCollapsed ? "80px" : "0px", // Adjust margin based on collapsed state
          transition: "margin-left 0.2s ease", // Smooth transition when collapsing/expanding
        }}
      >
        <Routes>
          {/* Correct the path to `/list` under the current nested router */}
          <Route path="list" element={<ProjectsTable />} />

          {/* Dynamic route for project details */}
          <Route
            path=":id/*"
            element={
              <ProjectDetails
                leftCollapsed={leftCollapsed}
                rightCollapsed={rightCollapsed}
                setRightCollapsed={setRightCollapsed}
                id={id}
                idEpic={idEpic}
                idFeature={idFeature}
                idUserStory={idUserStory}
                isNewUserStory={isNewUserStory} // Pass new user story flag
              />
            }
          />

          {/* Catch-all route for undefined paths */}
          <Route path="*" element={<Projects />} />
        </Routes>
      </Layout.Content>
    </Layout>
  );
};

export default ProjectsRouter;
