import React from "react";
import { Menu, Tooltip, Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import i18n from "i18next";
import projectIcon from "./../../static/project_icon.png";
import { truncateText } from "../../helper/utils";
import { useNavigate } from "react-router-dom";
const { SubMenu } = Menu;
const { Item: MenuItem, ItemGroup: MenuItemGroup } = Menu;

export const sortByKey = (array, key, order = "asc") => {
  return [...array].sort((a, b) => {
    if (order === "asc") {
      return a[key] - b[key];
    } else if (order === "desc") {
      return b[key] - a[key];
    }
    return 0; // Default case (if the order is not 'asc' or 'desc')
  });
};
export const renderStoryItems = (
  feature,
  selectedStoryId,
  classes,
  handleSelectStory,
  projectId,
  epicId,
  navigate
) => {
  return (
    <>
      {feature.userStories.map((story, storyIndex) => (
        <MenuItem
          key={`story_${story.id}`}
          className={`${selectedStoryId === story.id ? classes.selected : ""} ${
            classes.childMenuItem
          }`}
          onClick={() =>
            handleSelectStory({
              story,
              projectId,
              epicId,
              featureId: feature.id,
            })
          }
        >
          <Tooltip title={story.title}>
            {`${storyIndex + 1}. ${truncateText(
              story.title || story.description,
              15
            )}`}
          </Tooltip>
        </MenuItem>
      ))}
      {/* New User Story Button */}
      <MenuItem
        key={`new_story_${feature.id}`}
        className={classes.newStoryButton}
      >
        <Button
          type="primary"
          className="bg-black text-white border-black w-full"
          icon={<PlusOutlined />}
          onClick={() => {
            console.log({ projectId, epicId, feature });

            navigate(`/en/projects/${projectId}/${epicId}/${feature.id}/new`);
          }}
        >
          New User Story
        </Button>
      </MenuItem>
    </>
  );
};

// Render Feature Items
export const renderFeatureItems = (
  epic,
  selectedFeatureId,
  selectedStoryId,
  classes,
  handleSelectStory,
  projectId,
  epicId,
  navigate
  //handleNewStory
) =>
  epic.features.map((feature, featureIndex) => (
    <SubMenu
      key={`feature_${feature.id}`}
      title={
        <Tooltip title={feature.description}>
          <span
            className={`${
              selectedFeatureId === feature.id ? classes.selected : ""
            } ${classes.childMenuItem} ${classes.featureBackground}`}
          >
            {`${featureIndex + 1}. ${truncateText(feature.description, 15)}`}
          </span>
        </Tooltip>
      }
    >
      {renderStoryItems(
        feature,
        selectedStoryId,
        classes,
        handleSelectStory,
        projectId,
        epicId,
        navigate
      )}
    </SubMenu>
  ));

// Render Epic Items
export const renderEpicItems = (
  project,
  selectedEpicId,
  selectedFeatureId,
  selectedStoryId,
  classes,
  handleSelectStory,
  navigate
  //handleNewStory
) =>
  project.epics.map((epic, epicIndex) => (
    <SubMenu
      key={`epic_${epic.id}`}
      title={
        <Tooltip title={epic.description}>
          <span
            className={`${selectedEpicId === epic.id ? classes.selected : ""} ${
              classes.childMenuItem
            } ${classes.epicBackground}`}
          >
            {`${epicIndex + 1}. ${truncateText(epic.description, 15)}`}
          </span>
        </Tooltip>
      }
    >
      {renderFeatureItems(
        epic,
        selectedFeatureId,
        selectedStoryId,
        classes,
        handleSelectStory,
        project.id,
        epic.id,
        navigate
        //handleNewStory
      )}
    </SubMenu>
  ));

// Render Project Items
export const renderProjectItems = ({
  projects,
  selectedProjectId,
  selectedEpicId,
  selectedFeatureId,
  selectedStoryId,
  classes,
  handleSelectStory,
  navigate,
}) =>
  //handleNewStory
  projects.map((project, projectIndex) => (
    <SubMenu
      key={`project_${project.id}`}
      icon={
        <img
          src={projectIcon}
          alt="Project Icon"
          className={classes.menuIcon}
        />
      }
      title={
        <Tooltip title={project.title}>
          <Link
            className={`text-black font-semibold text-sm ${
              selectedProjectId === project.id ? classes.selected : ""
            }`}
          >
            <span className={classes.projectBackground}>{`${
              projectIndex + 1
            }. ${truncateText(project.title, 15)}`}</span>
          </Link>
        </Tooltip>
      }
    >
      {renderEpicItems(
        project,
        selectedEpicId,
        selectedFeatureId,
        selectedStoryId,
        classes,
        handleSelectStory,
        navigate
        //handleNewStory
      )}
    </SubMenu>
  ));

// Example usage in the JSX component
const ProjectMenu = ({
  projects,
  selectedProjectId,
  selectedEpicId,
  selectedFeatureId,
  selectedStoryId,
  classes,
  handleSelectStory,
  //handleNewStory,
}) => {
  return (
    <Menu mode="inline" theme="light">
      {renderProjectItems(
        projects,
        selectedProjectId,
        selectedEpicId,
        selectedFeatureId,
        selectedStoryId,
        classes,
        handleSelectStory
        //handleNewStory
      )}
    </Menu>
  );
};

export default ProjectMenu;
