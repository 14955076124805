// features/rootReducer.js
import { combineReducers } from "@reduxjs/toolkit";
import counterReducer from "./features/counter";
import userReducer from "./features/userSlice";
import transcriptReducer from "./features/transcriptSlice";
import { projectService } from "./features/projectsSlice";
import { usersService } from "./features/usersSlice";
import selectedFeatureReducer from "./features/selectedFeatureSlice";
import { authApi } from "./features/authSlice";
import { requestService } from "./features/requestSlice";
import { configurationApi } from "./features/configurationsSlice";
import { notificationsService } from "./features/notificationsSlice";
import { userApi } from "./features/userApi";
import { userStoryService } from "./features/userStoryService";

// Combine all the reducers
const appReducer = combineReducers({
  counter: counterReducer,
  authApi: authApi.reducer,
  [requestService.reducerPath]: requestService.reducer,
  [configurationApi.reducerPath]: configurationApi.reducer,
  [notificationsService.reducerPath]: notificationsService.reducer,
  [userApi.reducerPath]: userApi.reducer,
  user: userReducer,
  transcript: transcriptReducer,

  [usersService.reducerPath]: usersService.reducer,
  [projectService.reducerPath]: projectService.reducer,
  [userStoryService.reducerPath]: userStoryService.reducer,
  selectedFeature: selectedFeatureReducer,
});

// Root reducer to handle reset state action
const rootReducer = (state, action) => {
  if (action.type === "resetState") {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
