// features/userSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  transcript: null,
};

const transcriptSlice = createSlice({
  name: "transcript",
  initialState,
  reducers: {
    setTranscript: (state, action) => {
      state.transcript = action.payload;
    },
    clearTranscript: (state) => {
      state.transcript = null;
    },
  },
});

export const { setTranscript, clearTranscript } = transcriptSlice.actions;

export default transcriptSlice.reducer;
