import React from "react";
import { Input, Spin } from "antd";
import { AudioOutlined, SendOutlined } from "@ant-design/icons";

const { TextArea } = Input;

const MessageInput = ({
  messageInput,
  setMessageInput,
  handleSend,
  loading,
  disableActions,
  status,
  startRecording,
  stopRecording,
}) => {
  return (
    <div className="flex items-center ml-3 mr-3 bg-white rounded-lg p-5 mb-5 shadow-md">
      {status === "processing" ? (
        <Spin className="text-2xl mr-3" />
      ) : (
        <AudioOutlined
          className={`text-2xl mr-3 cursor-pointer ${
            disableActions ? "text-gray-400 cursor-not-allowed" : ""
          }`}
          onClick={
            disableActions
              ? undefined
              : status === "recording"
              ? stopRecording
              : startRecording
          }
        />
      )}
      <TextArea
        className="flex-grow mr-3"
        value={messageInput}
        onChange={(e) => setMessageInput(e.target.value)}
        placeholder="Type message"
        autoSize={{ minRows: 1, maxRows: 6 }}
        bordered={false}
        disabled={disableActions}
      />
      {loading || status === "processing" ? (
        <Spin className="text-2xl" />
      ) : (
        <SendOutlined
          className={`text-2xl cursor-pointer ${
            disableActions ? "text-gray-400 cursor-not-allowed" : ""
          }`}
          onClick={disableActions ? undefined : handleSend}
        />
      )}
    </div>
  );
};

export default MessageInput;
