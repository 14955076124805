import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { List, Button, message } from "antd";
import { UserOutlined, LogoutOutlined, TeamOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import userIcon from "../../static/project_icon.png"; // Assuming you have a user icon
import { clearUser } from "../../features/userSlice";
import { useGetUserByIdQuery } from "../../features/usersSlice"; // Import the hook to fetch user by ID
import { useGetUserProjectsQuery } from "../../features/projectsSlice"; // Import the hook to fetch user's projects
import i18n from "i18next";

const UsersLeft = () => {
  const [userId, setUserId] = useState(null);
  const { data: user, error: userError } = useGetUserByIdQuery(userId, {
    skip: !userId,
  });
  const { data: projects, error: projectError } = useGetUserProjectsQuery(
    userId,
    { skip: !userId }
  );

  const users = useSelector(
    (state) =>
      state.usersService.queries["getAllUsers(undefined)"]?.data?.users || []
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(clearUser()); // Clears user state
    dispatch({ type: "resetState" }); // Resets all states
    navigate(`/${i18n.language}/login`); // Redirect to login page
  };

  const handleUserClick = (userId) => {
    if (userId) setUserId(userId);
  };

  useEffect(() => {
    if (userError) {
      message.error("Failed to fetch user details.");
    }
    if (projectError) {
      message.error("Failed to fetch user projects.");
    }
  }, [userError, projectError, user, projects]);

  return (
    <div className="w-64 bg-yellow-400 p-5 flex flex-col">
      {/* Top Section */}
      <div className="flex-grow px-5">
        <Button
          type="primary"
          block
          className="bg-black mb-5 font-semibold text-base"
          onClick={() => navigate("/new-user")}
        >
          + New User
        </Button>
        <Button
          type="primary"
          block
          className="bg-black mb-5 font-semibold text-base"
          onClick={() => navigate("/projects")}
        >
          ← Projects
        </Button>
        <List
          itemLayout="vertical"
          dataSource={users}
          renderItem={(user) => (
            <List.Item
              key={user.id}
              className="p-2 rounded mb-2 bg-yellow-400 flex items-center cursor-pointer"
              onClick={() => handleUserClick(user.id)}
            >
              <div className="flex items-center">
                <img src={userIcon} alt="User Icon" className="w-5 h-5 mr-2" />
                <span className="text-black font-semibold text-sm">
                  {user.userName}
                </span>
              </div>
            </List.Item>
          )}
        />
      </div>

      {/* Bottom Section */}
      <div className="w-full border-t border-gray-400 px-4 mt-5">
        {/* User Management Button */}
        {
          <Button
            icon={<TeamOutlined />}
            block
            className="text-left mt-2 w-full border-none shadow-none bg-yellow-400 text-black font-semibold text-sm"
            onClick={() => navigate("/user-management")}
          >
            User Management
          </Button>
        }

        {/* My Account Button */}
        <Button
          icon={<UserOutlined />}
          block
          className="text-left mt-2 w-full border-none shadow-none bg-yellow-400 text-black font-semibold text-sm"
          onClick={() => navigate("/#")}
        >
          My account
        </Button>

        {/* Logout Button */}
        <Button
          icon={<LogoutOutlined />}
          block
          className="text-left mt-2 w-full border-none shadow-none bg-yellow-400 text-black font-semibold text-sm"
          onClick={handleLogout}
        >
          Log out
        </Button>
      </div>
    </div>
  );
};

export default UsersLeft;
