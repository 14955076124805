import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { jwtDecode } from "jwt-decode";

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,
  prepareHeaders: (headers, { getState }) => {
    const state = getState();
    const token = state?.user?.token;

    if (token) {
      try {
        // Decode the token to get its payload
        const decodedToken = jwtDecode(token);
        const currentTime = Date.now() / 1000;

        // Check if the token is expired
        if (decodedToken.exp > currentTime) {
          headers.set("token", token);
          console.log("Token is valid and has been set in the headers.");
        } else {
          console.log("Token is expired and will not be set in the headers.");
          localStorage.removeItem("user");
          localStorage.removeItem("token");
        }
      } catch (error) {
        console.error("Failed to decode the token", error);
      }
    } else {
      console.log("No token found in state.");
    }

    return headers;
  },
});

export const usersService = createApi({
  reducerPath: "usersService",
  baseQuery,
  tagTypes: ["Users", "UserById"], // Define the tags
  endpoints: (builder) => ({
    createUser: builder.mutation({
      query: (userData) => ({
        url: "/users",
        method: "POST",
        body: userData,
      }),
      invalidatesTags: ["Users"],
    }),
    getUserById: builder.query({
      query: (userId) => ({
        url: `/users/${userId}`,
        method: "GET",
      }),
      providesTags: ["UserById"], // Use a general "UserById" tag
      // This ensures that the query is cached as 'getUserById' without the argument
      serializeQueryArgs: () => {
        return;
      },
      merge: (currentCache, newItems) => {
        return { ...currentCache, ...newItems };
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),
    getAllUsers: builder.query({
      query: () => ({
        url: "/users",
        method: "GET",
      }),
      providesTags: ["Users"],
      transformResponse: (response) => response,
    }),
    updateUser: builder.mutation({
      query: ({ userId, updatedData }) => {
        return {
          url: `/users/${userId}/update`,
          method: "POST",
          body: updatedData,
        };
      },
      invalidatesTags: ["UserById"], // Invalidate the general "UserById" tag
    }),
  }),
});

export const {
  useCreateUserMutation,
  useGetUserByIdQuery,
  useGetAllUsersQuery,
  useUpdateUserMutation,
} = usersService;
