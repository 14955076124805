import React from "react";
import { useSelector } from "react-redux";
import "tailwindcss/tailwind.css";
import UserInformation from "./UserInformation";
import UserProjectsTable from "./UserProjectsTable";
import { useUpdateUserMutation } from "../../features/usersSlice"; // Import the mutation hook

const UsersManagementMainContent = () => {
  // Load user data from the store
  const user = useSelector(
    (state) => state.usersService.queries[`getUserById(undefined)`]?.data
  );

  // Load user projects from the store
  const projects = useSelector(
    (state) => state.projectService.queries[`getUserProjects(undefined)`]?.data
  );

  const isLoadingUser = !user;
  const isLoadingProjects = !projects;

  const [updateUser] = useUpdateUserMutation(); // Hook to update user

  const handleUpdateUser = async (updatedUserData) => {
    try {
      await updateUser({ userId: user.id, updatedData: updatedUserData });
      // You can add additional success logic here, such as showing a success message
    } catch (error) {
      // Handle the error if the update fails
      console.error("Failed to update user", error);
    }
  };

  const handleUpdatePassword = async (newPassword) => {
    try {
      // Assuming you have an endpoint to update the password
      // Call the API here to update the password
      await updateUser({
        userId: user.id,
        updatedData: { password: newPassword },
      });
    } catch (error) {
      console.error("Failed to update password", error);
    }
  };

  return (
    <div className="p-8 flex flex-col items-center bg-gray-100 min-h-screen">
      <UserInformation
        user={user}
        isLoadingUser={isLoadingUser}
        onUpdateUser={handleUpdateUser} // Pass the updateUser function
        onUpdatePassword={handleUpdatePassword} // Pass the updatePassword function
      />
      <UserProjectsTable
        projects={projects}
        isLoadingProjects={isLoadingProjects}
      />
    </div>
  );
};

export default UsersManagementMainContent;
