// src/features/audioSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setTranscript } from "./transcriptSlice";

export const sendAudioForTranscription = createAsyncThunk(
  "audio/sendAudioForTranscription",
  async (file, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      formData.append("files", file);

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/file/upload/audio`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("File upload failed");
      }
      /* 
      const data = await response.json();
      const uploadedFileData = {
        uuid: data.files[0].filename,
        description: { ar: "", en: "Audio Transcript" },
        title: { ar: "", en: "Transcript" },
        size: data.files[0].size,
        extension: data.files[0].extension,
        mimetype: data.files[0].mimetype,
        originalname: data.files[0].originalname,
      };

      // Process the uploaded file
      const finalResponse = await fetch(
        `${process.env.REACT_APP_API_URL}/file`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Forscan: true,
          },
          body: JSON.stringify([uploadedFileData]),
        }
      );

      if (!finalResponse.ok) {
        throw new Error("Adding file to database failed");
      } */

      const transcriptData = await response.json();

      return transcriptData;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const audioSlice = createSlice({
  name: "audio",
  initialState: {
    transcript: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(sendAudioForTranscription.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(sendAudioForTranscription.fulfilled, (state, action) => {
        state.loading = false;
        state.transcript = action.payload;
      })
      .addCase(sendAudioForTranscription.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default audioSlice.reducer;
