import React, { useState } from "react";
import { Typography, Button, Input, message, Spin } from "antd";
import { SendOutlined, AudioFilled, AudioOutlined } from "@ant-design/icons";
import { createUseStyles } from "react-jss";
import { useDispatch } from "react-redux";
import { useReactMediaRecorder } from "react-media-recorder-2";
import logo from "./../static/home_logo.png";
import { sendAudioForTranscription } from "../features/audioSlice";
import { setTranscript } from "../features/transcriptSlice";
import { useCreateProjectMutation } from "../features/projectsSlice"; // Import the hook
import { selectWireframe } from "../features/selectedFeatureSlice";
import i18n from "i18next";
import { Link, useNavigate } from "react-router-dom";
const { Title, Text } = Typography;
const { TextArea } = Input;

const useStyles = createUseStyles({
  contentUpper: {
    padding: "30px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#F8F8F8",
    height: "90%",
  },
  contentLower: {
    display: "flex",
    alignItems: "center",
    width: "70%",
    padding: "10px 15px",
    backgroundColor: "#F1F1F1",
    borderRadius: "20px",
  },
  messageInput: {
    flexGrow: 1,
    backgroundColor: "transparent",
    padding: "10px",
    fontSize: "14px",
    fontFamily: "Inter",
    resize: "none", // Prevent resizing from the corner
  },
  messageIcon: {
    fontSize: "20px",
    color: "#8C8C8C",
    cursor: "pointer", // Make the icon clickable
  },
  recordingButton: {
    position: "relative",
    width: "80px",
    height: "80px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "20px",
    backgroundColor: "red",
    color: "white",
    borderRadius: "50%",
    "&::before": {
      content: '""',
      position: "absolute",
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      border: "5px solid #ccc",
      animation: "$ring 1.5s infinite",
    },
  },
  recordingButtonInactive: {
    position: "relative",
    width: "80px",
    height: "80px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "20px",
    backgroundColor: "white",
    color: "red",
    borderRadius: "50%",
    border: "2px solid red",
  },
  "@keyframes ring": {
    "0%": {
      transform: "scale(1)",
      borderColor: "#ccc",
    },
    "50%": {
      transform: "scale(1.2)",
      borderColor: "#007bff",
    },
    "100%": {
      transform: "scale(1)",
      borderColor: "#ccc",
    },
  },
});

const ProjectsMainContent = () => {
  const [messageInput, setMessageInput] = useState("");
  const [loading, setLoading] = useState(false); // Add a loading state
  const [audioLoading, setAudioLoading] = useState(false); // Add a separate loading state for audio transcription
  const dispatch = useDispatch();
  const classes = useStyles();

  // Use the mutation hook
  const [createProject] = useCreateProjectMutation();
  const navigate = useNavigate();
  const { status, startRecording, stopRecording } = useReactMediaRecorder({
    audio: true,
    onStop: async (blobUrl) => {
      setAudioLoading(true); // Set audio loading to true when the audio is being processed
      try {
        const response = await fetch(blobUrl);
        const blob = await response.blob();
        const file = new File([blob], "audio_recording.wav", {
          type: blob.type,
        });

        const transcriptResponse = await dispatch(
          sendAudioForTranscription(file)
        ).unwrap();

        dispatch(setTranscript(transcriptResponse?.files?.[0]?.transcript));

        // Set the transcript as the value of the message input
        setMessageInput(transcriptResponse?.files?.[0]?.transcript || "");

        message.success("Audio sent for transcription!");
      } catch (error) {
        message.error("Failed to send audio for transcription.");
      } finally {
        setAudioLoading(false); // Set audio loading to false once transcription is done
      }
    },
  });

  const handleSend = async () => {
    if (messageInput.trim()) {
      setLoading(true); // Set loading to true when sending starts
      try {
        const proRes = await createProject(messageInput).unwrap();

        const firstStory = proRes?.project?.firstStory;
        dispatch(
          selectWireframe({
            id: firstStory?.id,
            title: firstStory.title,
            description: firstStory.description,
            wireFrame: firstStory.wireFrame,
            projectId: firstStory?.projectId,
            project: {},
            epicId: firstStory.epicId,
            featureId: firstStory.featureId,
          })
        );
        dispatch(setTranscript(proRes?.project?.description));
        navigate(
          `/${i18n.language}/projects/${firstStory?.projectId}/${firstStory.epicId}/${firstStory.featureId}/${firstStory?.id}`
        );
        message.success("Project created successfully!");
        setMessageInput(""); // Clear the input after sending
      } catch (error) {
        console.log({ error });

        message.error("Failed to create project.");
      } finally {
        setLoading(false); // Set loading to false when sending ends
      }
    } else {
      message.warning("Please enter a message.");
    }
  };

  const disableActions = loading || audioLoading;

  return (
    <div
      style={{
        padding: "30px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#F8F8F8",
        minHeight: "100vh",
        height: "100%",
      }}
    >
      <div className={classes.contentUpper}>
        <img
          src={logo}
          alt="POC-IT Logo"
          style={{ width: "300px", marginBottom: "20px" }}
        />
        <Title
          level={2}
          style={{
            marginBottom: "20px",
            fontFamily: "Inria Seri",
            fontWeight: "400",
            fontSize: 48,
          }}
        >
          POC-IT
        </Title>
        <Text
          style={{
            textAlign: "center",
            marginBottom: "40px",
            fontFamily: "Inria Seri",
            fontWeight: "400",
            fontSize: 24,
          }}
        >
          Hi, What would you like to build today? <br />
          Please press the button and record your query.
        </Text>

        <Button
          shape="circle"
          size="large"
          className={
            status === "recording"
              ? classes.recordingButton
              : classes.recordingButtonInactive
          }
          onClick={status === "recording" ? stopRecording : startRecording}
          disabled={disableActions && status !== "recording"} // Only disable the button when not recording
        >
          <AudioFilled style={{ fontSize: "40px" }} />
        </Button>

        {/* Display a loading spinner while waiting for transcription */}
        {audioLoading && (
          <Spin
            style={{ marginTop: "20px", color: "#007bff" }}
            tip="Processing audio..."
          />
        )}
      </div>

      <div className={classes.contentLower}>
        <AudioOutlined
          className={classes.messageIcon}
          onClick={
            disableActions
              ? undefined
              : status === "recording"
              ? stopRecording
              : startRecording
          }
          style={{ cursor: disableActions ? "not-allowed" : "pointer" }} // Change cursor style when disabled
        />
        <TextArea
          className={classes.messageInput}
          value={messageInput}
          onChange={(e) => setMessageInput(e.target.value)}
          placeholder="Type message"
          autoSize={{ minRows: 1, maxRows: 6 }} // Allows the textarea to expand and contract based on content
          bordered={false}
          disabled={disableActions} // Disable the input when disableActions is true
        />
        {loading ? (
          <Spin className={classes.messageIcon} />
        ) : (
          <SendOutlined
            className={classes.messageIcon}
            onClick={disableActions ? undefined : handleSend}
            style={{ cursor: disableActions ? "not-allowed" : "pointer" }} // Change cursor style when disabled
          />
        )}
      </div>
    </div>
  );
};

export default ProjectsMainContent;
