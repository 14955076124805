import React, { useState } from "react";
import { Select, Button, message, Spin } from "antd";
import { LoginOutlined, LogoutOutlined } from "@ant-design/icons";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { clearUser } from "../features/userSlice";
import axios from "axios";

const { Option } = Select;

const Header = ({ projectId }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user.user);

  // State to manage loading status and message
  const [loading, setLoading] = useState(false);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("lng", lng);
    const currentPath = location.pathname.substring(3);
    navigate(`/${lng}${currentPath}`);
    localStorage.setItem("language", lng);
  };

  const handleLogout = () => {
    dispatch(clearUser()); // Clears user state
    dispatch({ type: "resetState" }); // Resets all states
    navigate(`/${i18n.language}/login`); // Redirect to login page
  };

  const handleLogIn = () => {
    navigate(`/${i18n.language}/login`); // Redirect to login page
  };

  // Function to handle the PDF download
  const downloadProjectPDF = async () => {
    try {
      setLoading(true); // Set loading to true before making the request

      const response = await axios({
        url: `${process.env.REACT_APP_API_URL}/project/${projectId}/pdf`,
        method: "GET",
        responseType: "blob", // Important to specify the response type as 'blob' for file downloads
      });

      // Create a URL for the blob file and trigger download
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `project_${projectId}.pdf`); // Set the file name
      document.body.appendChild(link);
      link.click();

      message.success("File downloaded successfully!");
    } catch (error) {
      message.error("Failed to download the file. Please try again.");
    } finally {
      setLoading(false); // Set loading to false after the download is complete
    }
  };

  return (
    <header
      className="flex justify-between items-center"
      style={{
        margin: "10px",
        height: "50px",
        backgroundColor: "#5A5A5A", // Dark gray background
        borderRadius: "8px", // Rounded corners
        padding: "20px", // Padding on the left and right
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Subtle shadow
      }}
    >
      {/* Loading Spinner */}
      {loading && (
        <div
          style={{
            position: "fixed",
            top: "10px",
            left: "50%",
            transform: "translateX(-50%)",
            zIndex: 1000,
            backgroundColor: "#fff",
            padding: "10px 20px",
            borderRadius: "8px",
            color: "#5a5a5a",
            boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Spin size="small" /> Downloading...
        </div>
      )}

      <div className="flex-shrink-0">
        <span className="font-bold text-white text-lg">Poc-IT</span>{" "}
        {/* White text for logo */}
      </div>
      <nav
        className="flex-1 flex justify-end space-x-4"
        style={{
          backgroundColor: "#5A5A5A",
          margin: "0 20px", // Adjust spacing
        }}
      >
        {user && (
          <>
            <NavLink
              className="px-3 py-1 text-gray-700 bg-white hover:bg-gray-200 rounded-lg text-sm border-r-7"
              to={`/${i18n.language}/projects/list`}
            >
              Projects
            </NavLink>

            <NavLink
              className="px-3 py-1 text-gray-700 bg-white hover:bg-gray-200 rounded-lg text-sm border-r-7"
              to={`/${i18n.language}/projects/1`}
            >
              Tasks
            </NavLink>
            <NavLink
              className="px-3 py-1 text-gray-700 bg-white hover:bg-gray-200 rounded-lg text-sm"
              to={`/${i18n.language}/projects/1`}
            >
              Wireframes
            </NavLink>
            <Button
              className="px-3 py-1 text-gray-700 bg-white hover:bg-gray-200 rounded-lg text-sm"
              onClick={downloadProjectPDF} // Trigger PDF download on button click
              disabled={!projectId}
            >
              Backlog
            </Button>
          </>
        )}
      </nav>
      <div className="flex items-center space-x-4">
        {user ? (
          <Button
            type="primary"
            icon={<LogoutOutlined />}
            onClick={handleLogout}
            style={{
              backgroundColor: "#f56565",
              borderColor: "#f56565",
              borderRadius: "8px",
              padding: "0 10px",
            }}
          />
        ) : (
          <Button
            type="primary"
            icon={<LoginOutlined />}
            onClick={handleLogIn}
            style={{
              backgroundColor: "#48bb78",
              borderColor: "#48bb78",
              borderRadius: "8px",
              padding: "0 10px",
            }}
          />
        )}
      </div>
    </header>
  );
};

export default Header;
