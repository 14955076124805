import React, { useEffect, useState } from "react";
import { Row, Col, Typography, Form, Input, Button, notification } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useLoginUserMutation } from "./../features/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../features/userSlice";
import { createUseStyles } from "react-jss";
import i18n from "i18next";
import loginBackground from "./../../src/static/login_background.svg"; // Import the background image

const { Title, Text } = Typography;

const useStyles = createUseStyles({
  loginContainer: {
    backgroundImage: `url(${loginBackground})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    minHeight: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  loginBox: {
    backgroundColor: "#ffffff",
    padding: "40px",
    borderRadius: "8px",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    textAlign: "center",
  },
  loginTitle: {
    fontSize: "24px",
    marginBottom: "10px",
    fontWeight: 600,
    color: "#333",
    textAlign: "left",
  },
  loginHeading: {
    textAlign: "left",
    fontSize: "28px",
    marginBottom: "30px",
    fontWeight: 700,
    color: "#333",
  },
  loginButton: {
    backgroundColor: "#ffc107",
    borderColor: "#ffc107",
    color: "#fff",
    fontWeight: 600,
    width: "100%",
    height: "50px",
    fontSize: "16px",
  },
  forgotPasswordLink: {
    fontSize: "14px",
    color: "#888",
    marginTop: "10px",
  },
  signupText: {
    marginTop: "20px",
    color: "#888",
  },
  signupLink: {
    fontWeight: 600,
    color: "#ffc107",
    paddingLeft: "5px",
  },
});

const Login = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const user = useSelector((state) => state.user);
  const [loginUser, { isLoading }] = useLoginUserMutation();
  const dispatch = useDispatch();
  const classes = useStyles(); // Initialize the useStyles hook

  useEffect(() => {
    if (user?.user?.id) navigate(`/${i18n.language}`);
  }, [user, navigate]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const openNotification = (type, message) => {
    notification[type]({
      message,
      duration: 2,
    });
  };

  const handleSubmit = async (values) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(values.email)) {
      openNotification("error", "Invalid email format");
      return;
    }
    try {
      const response = await loginUser({
        email: values.email,
        password: values.password,
      }).unwrap();

      if (response.success) {
        dispatch(setUser({ user: response.user, token: response.token }));
        openNotification("success", "Login successful!");
        // Delay navigation to allow the notification to display properly
        setTimeout(() => {
          navigate("/projects");
        }, 1000); // 1 second delay before navigation
      } else {
        openNotification("error", response.message);
      }
    } catch (error) {
      openNotification("error", "Invalid email or password");
    }
  };

  return (
    <div className={classes.loginContainer}>
      <Row justify="center" style={{ width: "100%" }} align="middle">
        <Col xs={22} sm={16} md={12} lg={8}>
          <div className={classes.loginBox}>
            <Title level={3} className={classes.loginTitle}>
              {t("login.welcomeBack")} 👋
            </Title>
            <Title level={2} className={classes.loginHeading}>
              {t("login.signInToYourAccount")}
            </Title>
            <Form layout="vertical" onFinish={handleSubmit}>
              <Form.Item
                label={t("login.email")}
                name="email"
                rules={[{ required: true, message: t("login.emailRequired") }]}
              >
                <Input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </Form.Item>
              <Form.Item
                label={t("login.password")}
                name="password"
                rules={[
                  { required: true, message: t("login.passwordRequired") },
                ]}
              >
                <Input.Password
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={isLoading}
                  className={classes.loginButton}
                >
                  {isLoading ? t("login.loggingIn") : t("login.continue")}
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  type="link"
                  className={classes.forgotPasswordLink}
                  onClick={() => navigate("/forgot-password")}
                >
                  {t("login.forgotPassword")}
                </Button>
              </Form.Item>
            </Form>
            <Text className={classes.signupText}>
              {t("login.noAccount")}{" "}
              <Button
                type="link"
                className={classes.signupLink}
                onClick={() => navigate("/signup")}
              >
                {t("login.signUp")}
              </Button>
            </Text>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Login;
